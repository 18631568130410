<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            md="10"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <p class="h5 mt-50 mr-75 ml-50">
              조회 타입
            </p>
            <b-form-radio-group
              v-model="query.viewType"
            >
              <b-form-radio value="summary">
                요약
              </b-form-radio>
              <b-form-radio value="detail">
                상세
              </b-form-radio>
            </b-form-radio-group>
            <p class="h5 mt-50 mr-75 ml-50">
              조회 기준
            </p>
            <b-form-radio-group
              v-model="query.searchType"
            >
              <b-form-radio :value="1">
                의원
              </b-form-radio>
              <b-form-radio :value="2">
                의사
              </b-form-radio>
            </b-form-radio-group>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col
            md="8"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="query.clientId"
              :options="codes.clients"
              placeholder="제약사"
              :reduce="option => option.code"
              class="el-def"
              style="width: 430px"
            />
            <v-select
              v-model="query.status"
              :options="codes.status"
              placeholder="진행 상태"
              :reduce="option => option.code"
              class="el-def"
            />
            <b-form-datepicker
              v-model="query.searchStartDate"
              class="el-def"
              placeholder="진행기간(Fr)"
              style="width: 250px"
            />
            <label class="ml-25 mr-50">~</label>
            <b-form-datepicker
              v-model="query.searchEndDate"
              class="el-def"
              placeholder="진행기간(To)"
              style="width: 250px"
            />
            <label style="width: 160px">(*당일데이터 조회 불가)</label>
          </b-col>
          <b-col
            md="4"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="btn-search"
              @click.prevent="fetchData()"
            >
              조회
            </b-button>
            <b-button
              variant="outline-dark"
              class="btn-search ml-1"
              @click.prevent="excelDownload()"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span>엑셀 다운로드</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-card>
      <summary-grid
        v-if="viewType === 'summary'"
        :data="seminarData"
        :search-type="searchType"
      />

      <detail-grid
        v-else-if="viewType === 'detail'"
        :data="seminarData"
        :search-type="searchType"
      />
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import vSelect from 'vue-select'
import { onMounted, ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dayjs from 'dayjs'
import ExcelJS from "exceljs"
import { saveAs } from "file-saver"
import SummaryGrid from './grid/SummaryGrid.vue'
import DetailGrid from './grid/DetailGrid.vue'

export default {
  components: {
    vSelect,
    SummaryGrid,
    DetailGrid,
  },

  setup() {
    onMounted(() => {
      fetchCodes()
      fetchData()
    })

    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon

      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }

      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    const query = ref({
      viewType: 'summary',
      searchType: 1,
      clientId: null,
      status: null,
      searchStartDate: dayjs().add(-1, 'month').format('YYYY-MM-DD'),
      searchEndDate: dayjs().add(-1, 'day').format('YYYY-MM-DD'),
    })

    const codes = ref({
      clients: [],
      status: [],
    })

    const viewType = ref("summary")
    const searchType = ref(1)
    const seminarData = ref([])

    const fetchCodes = () => {
      axios.get('/fa/webseminar/codes')
        .then(rs => {
          const {
            webSeminarStatus,
            clients,
          } = rs.data

          codes.value.status = webSeminarStatus.filter(e => e.code !== 90)
          codes.value.clients = clients
        })
        .catch(() => {
          pushToast('danger', '코드 데이터를 불러오는데 실패하였습니다.')
        })
    }

    const fetchData = () => {
      const frDate = dayjs(query.value.searchStartDate)
      const toDate = dayjs(query.value.searchEndDate)

      if (Math.abs(frDate.diff(toDate, 'day')) > 90) {
        pushToast('warning', '조회 기간은 최대 90일을 넘을 수 없습니다.')
        return
      }

      axios.get(`/fa/statistics/seminar/${query.value.viewType}`, {
        params: {
          searchType: query.value.searchType,
          clientId: query.value.clientId,
          status: query.value.status,
          startDate: query.value.searchStartDate,
          endDate: query.value.searchEndDate,
        },
      })
        .then(rs => {
          viewType.value = query.value.viewType
          searchType.value = query.value.searchType

          seminarData.value = rs.data
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '데이터 조회 중 오류가 발생하였습니다.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const excelDownload = async () => {
      if (viewType.value === 'summary') summaryDownload()
      else if (viewType.value === 'detail') detailDownload()
    }

    const summaryDownload = async () => {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet("sheet1")

      worksheet.columns = [
        { header: "ID", key: "id", width: 15 },
        { header: "제약사", key: "clientName", width: 25 },
        { header: "제목", key: "title", width: 50 },
        { header: "진행 일시", key: "seminarDate", width: 25 },
        { header: "진행 여부", key: "status", width: 10 },
        { header: "진료과", key: "medicalDept", width: 20 },
        {
          header: "총 클릭 수", key: "totalClickCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: searchType.value === 1 ? "총 클릭 처수" : "총 클릭 명수", key: "groupClickCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: "사전 예약 수(명)", key: "reserveCount", width: 15, style: { numFmt: '#,##0' },
        },
      ]

      let startRow = 2
      let endRow = startRow

      // eslint-disable-next-line array-callback-return
      seminarData.value.map(e => {
        e.summaryDepts.map(x => worksheet.addRow({
          id: e.id,
          clientName: e.clientName,
          title: e.title,
          seminarDate: `${dayjs(e.startDate).format('YYYY-MM-DD HH:mm')} ~ ${e.endDate === null ? '' : dayjs(e.endDate).format('HH:mm')}`,
          status: e.isStart ? '완료' : '예정',
          medicalDept: x.medicalDept,
          totalClickCount: x.totalClickCount,
          groupClickCount: x.groupClickCount,
          reserveCount: x.reserveCount,
        }))

        worksheet.addRow({
          id: e.id,
          clientName: e.clientName,
          title: e.title,
          seminarDate: `${dayjs(e.startDate).format('YYYY-MM-DD HH:mm')} ~ ${e.endDate === null ? '' : dayjs(e.endDate).format('HH:mm')}`,
          status: '소계',
          medicalDept: '',
          totalClickCount: e.summaryDepts.reduce((f, l) => f + l.totalClickCount, 0),
          groupClickCount: e.summaryDepts.reduce((f, l) => f + l.groupClickCount, 0),
          reserveCount: e.summaryDepts.reduce((f, l) => f + l.reserveCount, 0),
        })

        endRow += e.summaryDepts.length

        worksheet.mergeCells(`E${endRow}:F${endRow}`)
        worksheet.mergeCells(`A${startRow}:A${endRow}`)
        worksheet.mergeCells(`B${startRow}:B${endRow}`)
        worksheet.mergeCells(`C${startRow}:C${endRow}`)
        worksheet.mergeCells(`D${startRow}:D${endRow}`)
        worksheet.mergeCells(`E${startRow}:E${endRow - 1}`)

        startRow = endRow + 1
        endRow = startRow
      })

      worksheet.eachRow({ includeEmpty: true }, (row, number) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (number === 1) {
            // eslint-disable-next-line no-param-reassign
            cell.font = {
              bold: true,
            }

            // eslint-disable-next-line no-param-reassign
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D3D3D3' },
            }
          }

          // eslint-disable-next-line no-param-reassign
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }

          if (cell.value === '소계' || cell.value === '합계') {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'center',
            }
          } else {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'left',
            }
          }
        })
      })

      const mimeType = {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }

      const buffer = await workbook.xlsx.writeBuffer()

      const blob = new Blob([buffer], mimeType)

      saveAs(blob, "웹심포지엄 통계(요약).xlsx")
    }

    const detailDownload = async () => {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet("sheet1")

      worksheet.addRow()

      worksheet.getCell('A1').value = 'ID'
      worksheet.getCell('B1').value = '제약사'
      worksheet.getCell('C1').value = '제목'
      worksheet.getCell('D1').value = '진행 여부'
      worksheet.getCell('E1').value = '회원 구분'
      worksheet.getCell('F1').value = '진료과'

      worksheet.getCell('G1').value = '시청 팝업'
      worksheet.mergeCells(`G1:J1`)

      worksheet.getCell('K1').value = '닥터인포'
      worksheet.mergeCells(`K1:L1`)

      worksheet.getCell('M1').value = 'MY세미나 등록'
      worksheet.mergeCells(`M1:N1`)

      worksheet.getCell('O1').value = '자세히 보러가기'
      worksheet.mergeCells(`O1:P1`)

      worksheet.getCell('Q1').value = '사전예약 하러가기'
      worksheet.mergeCells(`Q1:R1`)

      worksheet.getCell('S1').value = '세미나 시청하기'
      worksheet.mergeCells(`S1:T1`)

      worksheet.getCell('U1').value = '사전 예약 수(명)'
      worksheet.getCell('G2').value = '노출 수'
      worksheet.getCell('H2').value = searchType.value === 1 ? '노출 처수' : '노출 명수'
      worksheet.getCell('I2').value = '클릭 수'
      worksheet.getCell('J2').value = searchType.value === 1 ? '클릭 처수' : '클릭 명수'
      worksheet.getCell('K2').value = '클릭 수'
      worksheet.getCell('L2').value = searchType.value === 1 ? '클릭 처수' : '클릭 명수'
      worksheet.getCell('M2').value = '클릭 수'
      worksheet.getCell('N2').value = searchType.value === 1 ? '클릭 처수' : '클릭 명수'
      worksheet.getCell('O2').value = '클릭 수'
      worksheet.getCell('P2').value = searchType.value === 1 ? '클릭 처수' : '클릭 명수'
      worksheet.getCell('Q2').value = '클릭 수'
      worksheet.getCell('R2').value = searchType.value === 1 ? '클릭 처수' : '클릭 명수'
      worksheet.getCell('S2').value = '클릭 수'
      worksheet.getCell('T2').value = searchType.value === 1 ? '클릭 처수' : '클릭 명수'

      worksheet.mergeCells(`A1:A2`)
      worksheet.mergeCells(`B1:B2`)
      worksheet.mergeCells(`C1:C2`)
      worksheet.mergeCells(`D1:D2`)
      worksheet.mergeCells(`E1:E2`)
      worksheet.mergeCells(`F1:F2`)
      worksheet.mergeCells(`U1:U2`)

      worksheet.columns = [
        { key: "id", width: 15 },
        { key: "clientName", width: 25 },
        { key: "title", width: 50 },
        { key: "status", width: 10 },
        { key: "memberType", width: 10 },
        { key: "medicalDept", width: 20 },
        { key: "totalPopupOpenCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "groupPopupOpenCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "totalPopupClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "groupPopupClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "totalDrInfoClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "groupDrInfoClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "totalMYClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "groupMYClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "totalDetailClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "groupDetailClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "totalReserveClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "groupReserveClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "totalWatchClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "groupWatchClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "reserveCount", width: 15, style: { numFmt: '#,##0' } },
      ]

      let startRow = 3
      let endRow = startRow

      // eslint-disable-next-line array-callback-return
      seminarData.value.map(e => {
        e.memberDetailDepts.map(x => worksheet.addRow({
          id: e.id,
          clientName: e.clientName,
          title: e.title,
          status: e.isStart ? '완료' : '예정',
          memberType: '회원',
          medicalDept: x.medicalDept,
          totalPopupOpenCount: x.totalPopupOpenCount,
          groupPopupOpenCount: x.groupPopupOpenCount,
          totalPopupClickCount: x.totalPopupClickCount,
          groupPopupClickCount: x.groupPopupClickCount,
          totalDrInfoClickCount: x.totalDrInfoClickCount,
          groupDrInfoClickCount: x.groupDrInfoClickCount,
          totalMYClickCount: x.totalMYClickCount,
          groupMYClickCount: x.groupMYClickCount,
          totalDetailClickCount: x.totalDetailClickCount,
          groupDetailClickCount: x.groupDetailClickCount,
          totalReserveClickCount: x.totalReserveClickCount,
          groupReserveClickCount: x.groupReserveClickCount,
          totalWatchClickCount: x.totalWatchClickCount,
          groupWatchClickCount: x.groupWatchClickCount,
          reserveCount: x.reserveCount,
        }))

        if (e.memberDetailDepts.length > 0) {
          worksheet.addRow({
            id: e.id,
            clientName: e.clientName,
            title: e.title,
            status: '',
            memberType: '소계',
            medicalDept: '',
            totalPopupOpenCount: e.memberDetailDepts.reduce((f, l) => f + l.totalPopupOpenCount, 0),
            groupPopupOpenCount: e.memberDetailDepts.reduce((f, l) => f + l.groupPopupOpenCount, 0),
            totalPopupClickCount: e.memberDetailDepts.reduce((f, l) => f + l.totalPopupClickCount, 0),
            groupPopupClickCount: e.memberDetailDepts.reduce((f, l) => f + l.groupPopupClickCount, 0),
            totalDrInfoClickCount: e.memberDetailDepts.reduce((f, l) => f + l.totalDrInfoClickCount, 0),
            groupDrInfoClickCount: e.memberDetailDepts.reduce((f, l) => f + l.groupDrInfoClickCount, 0),
            totalMYClickCount: e.memberDetailDepts.reduce((f, l) => f + l.totalMYClickCount, 0),
            groupMYClickCount: e.memberDetailDepts.reduce((f, l) => f + l.groupMYClickCount, 0),
            totalDetailClickCount: e.memberDetailDepts.reduce((f, l) => f + l.totalDetailClickCount, 0),
            groupDetailClickCount: e.memberDetailDepts.reduce((f, l) => f + l.groupDetailClickCount, 0),
            totalReserveClickCount: e.memberDetailDepts.reduce((f, l) => f + l.totalReserveClickCount, 0),
            groupReserveClickCount: e.memberDetailDepts.reduce((f, l) => f + l.groupReserveClickCount, 0),
            totalWatchClickCount: e.memberDetailDepts.reduce((f, l) => f + l.totalWatchClickCount, 0),
            groupWatchClickCount: e.memberDetailDepts.reduce((f, l) => f + l.groupWatchClickCount, 0),
            reserveCount: e.memberDetailDepts.reduce((f, l) => f + l.reserveCount, 0),
          })

          worksheet.mergeCells(`E${endRow}:E${endRow + e.memberDetailDepts.length - 1}`)

          endRow += e.memberDetailDepts.length

          worksheet.mergeCells(`E${endRow}:F${endRow}`)

          if (e.noneMemberDetailDepts.length > 0) endRow += 1
        }

        e.noneMemberDetailDepts.map(x => worksheet.addRow({
          id: e.id,
          clientName: e.clientName,
          title: e.title,
          status: e.isStart ? '완료' : '예정',
          memberType: '비회원',
          medicalDept: x.medicalDept,
          totalPopupOpenCount: x.totalPopupOpenCount,
          groupPopupOpenCount: x.groupPopupOpenCount,
          totalPopupClickCount: x.totalPopupClickCount,
          groupPopupClickCount: x.groupPopupClickCount,
          totalDrInfoClickCount: x.totalDrInfoClickCount,
          groupDrInfoClickCount: x.groupDrInfoClickCount,
          totalMYClickCount: x.totalMYClickCount,
          groupMYClickCount: x.groupMYClickCount,
          totalDetailClickCount: x.totalDetailClickCount,
          groupDetailClickCount: x.groupDetailClickCount,
          totalReserveClickCount: x.totalReserveClickCount,
          groupReserveClickCount: x.groupReserveClickCount,
          totalWatchClickCount: x.totalWatchClickCount,
          groupWatchClickCount: x.groupWatchClickCount,
          reserveCount: x.reserveCount,
        }))

        if (e.noneMemberDetailDepts.length > 0) {
          worksheet.addRow({
            id: e.id,
            clientName: e.clientName,
            title: e.title,
            status: '',
            memberType: '소계',
            medicalDept: '',
            totalPopupOpenCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.totalPopupOpenCount, 0),
            groupPopupOpenCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.groupPopupOpenCount, 0),
            totalPopupClickCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.totalPopupClickCount, 0),
            groupPopupClickCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.groupPopupClickCount, 0),
            totalDrInfoClickCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.totalDrInfoClickCount, 0),
            groupDrInfoClickCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.groupDrInfoClickCount, 0),
            totalMYClickCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.totalMYClickCount, 0),
            groupMYClickCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.groupMYClickCount, 0),
            totalDetailClickCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.totalDetailClickCount, 0),
            groupDetailClickCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.groupDetailClickCount, 0),
            totalReserveClickCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.totalReserveClickCount, 0),
            groupReserveClickCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.groupReserveClickCount, 0),
            totalWatchClickCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.totalWatchClickCount, 0),
            groupWatchClickCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.groupWatchClickCount, 0),
            reserveCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.reserveCount, 0),
          })

          worksheet.mergeCells(`E${endRow}:E${endRow + e.noneMemberDetailDepts.length - 1}`)

          endRow += e.noneMemberDetailDepts.length

          worksheet.mergeCells(`E${endRow}:F${endRow}`)
        }

        if (e.memberDetailDepts.length > 0 || e.noneMemberDetailDepts.length > 0) {
          worksheet.addRow({
            id: e.id,
            clientName: e.clientName,
            title: e.title,
            status: '',
            memberType: '합계',
            medicalDept: '',
            totalPopupOpenCount: e.memberDetailDepts.reduce((f, l) => f + l.totalPopupOpenCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.totalPopupOpenCount, 0),
            groupPopupOpenCount: e.memberDetailDepts.reduce((f, l) => f + l.groupPopupOpenCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.groupPopupOpenCount, 0),
            totalPopupClickCount: e.memberDetailDepts.reduce((f, l) => f + l.totalPopupClickCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.totalPopupClickCount, 0),
            groupPopupClickCount: e.memberDetailDepts.reduce((f, l) => f + l.groupPopupClickCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.groupPopupClickCount, 0),
            totalDrInfoClickCount: e.memberDetailDepts.reduce((f, l) => f + l.totalDrInfoClickCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.totalDrInfoClickCount, 0),
            groupDrInfoClickCount: e.memberDetailDepts.reduce((f, l) => f + l.groupDrInfoClickCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.groupDrInfoClickCount, 0),
            totalMYClickCount: e.memberDetailDepts.reduce((f, l) => f + l.totalMYClickCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.totalMYClickCount, 0),
            groupMYClickCount: e.memberDetailDepts.reduce((f, l) => f + l.groupMYClickCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.groupMYClickCount, 0),
            totalDetailClickCount: e.memberDetailDepts.reduce((f, l) => f + l.totalDetailClickCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.totalDetailClickCount, 0),
            groupDetailClickCount: e.memberDetailDepts.reduce((f, l) => f + l.groupDetailClickCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.groupDetailClickCount, 0),
            totalReserveClickCount: e.memberDetailDepts.reduce((f, l) => f + l.totalReserveClickCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.totalReserveClickCount, 0),
            groupReserveClickCount: e.memberDetailDepts.reduce((f, l) => f + l.groupReserveClickCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.groupReserveClickCount, 0),
            totalWatchClickCount: e.memberDetailDepts.reduce((f, l) => f + l.totalWatchClickCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.totalWatchClickCount, 0),
            groupWatchClickCount: e.memberDetailDepts.reduce((f, l) => f + l.groupWatchClickCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.groupWatchClickCount, 0),
            reserveCount: e.memberDetailDepts.reduce((f, l) => f + l.reserveCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.reserveCount, 0),
          })

          endRow += 1
          worksheet.mergeCells(`E${endRow}:F${endRow}`)
        }

        worksheet.mergeCells(`A${startRow}:A${endRow}`)
        worksheet.mergeCells(`B${startRow}:B${endRow}`)
        worksheet.mergeCells(`C${startRow}:C${endRow}`)
        worksheet.mergeCells(`D${startRow}:D${endRow}`)

        startRow = endRow + 1
        endRow = startRow
      })

      worksheet.eachRow({ includeEmpty: true }, (row, number) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (number === 1 || number === 2) {
            // eslint-disable-next-line no-param-reassign
            cell.font = {
              bold: true,
            }

            // eslint-disable-next-line no-param-reassign
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D3D3D3' },
            }
          }

          // eslint-disable-next-line no-param-reassign
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }

          if (cell.value === '소계' || cell.value === '합계') {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'center',
            }
          } else {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'left',
            }
          }
        })
      })

      const mimeType = {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }

      const buffer = await workbook.xlsx.writeBuffer()

      const blob = new Blob([buffer], mimeType)

      saveAs(blob, "웹심포지엄 통계(상세).xlsx")
    }

    return {
      query,
      codes,
      viewType,
      searchType,
      seminarData,
      fetchCodes,
      fetchData,
      excelDownload,
      summaryDownload,
      detailDownload,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.dropdown-menu {
  z-index: 100 !important;
}
</style>
